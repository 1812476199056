var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c(
        "el-aside",
        { staticClass: "aside" },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "background-color": "#FFFFFF",
                "text-color": "#222222",
                "active-text-color": "#FFFFFF",
                "default-active": _vm.activeIndex
              },
              on: { select: _vm.handleSelect }
            },
            [
              _c(
                "el-menu-item-group",
                { attrs: { title: "维权服务" } },
                [
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _c("div", { staticClass: "point" }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("长沙分中心介绍")
                    ])
                  ]),
                  _c("el-menu-item", { attrs: { index: "2" } }, [
                    _c("div", { staticClass: "point" }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("海外纠纷申报与受理")
                    ])
                  ]),
                  _c("el-menu-item", { attrs: { index: "3" } }, [
                    _c("div", { staticClass: "point" }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("海外知识产权动态"),
                      _c("br"),
                      _vm._v("及要点信息")
                    ])
                  ]),
                  _c("el-menu-item", { attrs: { index: "4" } }, [
                    _c("div", { staticClass: "point" }),
                    _c("div", { staticClass: "text" }, [_vm._v("维权援助")])
                  ]),
                  _c(
                    "el-menu-item",
                    { staticClass: "last", attrs: { index: "5" } },
                    [
                      _c("div", { staticClass: "point" }),
                      _c("div", { staticClass: "text" }, [_vm._v("其他信息")])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }